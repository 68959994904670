import '~/scripts/integrations/jquery-extended';

window.R.ui.ScheduleDemo = (function() {
    var LINK_CLASS = ".schedule-demo, .watch-demo";
    var SMALL_COMPANY_THRESHOLD = 20;

    var SD = function() {
      this.addEvents();
    };

    SD.prototype.companySizeFactory = function(result) {
        var $numberUsers = $("#numberUsers"),
            employeeSize = $numberUsers.prop('value');

        if (result.value) {
          if ( isNaN( parseInt($numberUsers.prop('value')) ) ) {
              return false;
          }

          if (employeeSize > SMALL_COMPANY_THRESHOLD) {
            this.largeCompanyAlert($numberUsers.prop('value'));
          } else {
            smallCompanyAlert(this.urlParams);
          }

          if (window.analytics) {
            window.analytics.track('demo-company-count-submitted', {size: parseInt($numberUsers.prop('value'))});
          }
        }
    };

    function askQuestionAlert(employeeSize) {
      const defaultSwalOpts = {
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        showConfirmButton: false
      };
      var url = '/sales-question?size='+employeeSize+' person company';
      // NOTE: firing swal immediately and then fetching remote content, 
      //       fetching first (which would be simpler) may also work, as it should be quick enough
      Swal.fire({
        title: 'Ask Us a Question',
        html: '<img src="/assets/icons/ajax-loader-medium.gif">',
        didOpen: function (swalEl) {
          $.get(url, function (data) {
            const $swalEl = $(swalEl);
            $swalEl.find('.swal2-html-container').html(data);

            // manually render recaptcha widget for async loaded form
            const recaptchaWrapper = $swalEl.find('.g-recaptcha');
            if (recaptchaWrapper.length) {
              grecaptcha.render(recaptchaWrapper[0]);
            }

            $swalEl.find('.captcha-trigger-button').on(R.touchEvent, function (e) {
              R.captchaInstance.attachCaptchaEvents(e);
            });

            const $formInDom = $(swalEl).find("form")
            // NOTE: ajax:error is already handled implicitly
            $formInDom.on('ajax:success', (evt, data, status, xhr) => {
              const responseUrl = data.location || xhr.getResponseHeader('Location');
              if (responseUrl) {
                Swal.close(); // close current modal
                Swal.fire({
                  html: '<iframe src=' + responseUrl + ' class="w-100 p-2 pb-0" style="height:270px"></iframe>',
                  ...defaultSwalOpts
                })
              }
            });
          });
        },
        ...defaultSwalOpts
      });
    }

    SD.prototype.watchVideoAlert = function(youtube_id) {
      var youtube_id = youtube_id || 'jY3CFZB3r0A';

      Swal.fire({
        title: 'Schedule Demo',
        html:
          '<iframe src="https://www.youtube.com/embed/'+youtube_id+'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="width: 100%; height: 280px;"></iframe>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        cancelButtonText: 'Watch More Videos',
        cancelButtonAriaLabel: 'Watch More Videos',
        confirmButtonText: 'Schedule Demo',
        confirmButtonAriaLabel: 'Schedule a Demo'
      }).then(function(result){
        if (result.value) {
          this.openUserCountAlert();
        } else if (result.dismiss === 'cancel') {
          Turbo.visit('/videos');
        }
      }.bind(this));
    };

    SD.prototype.largeCompanyAlert = function(size) {
      this.urlParams += '&UTM_COUNT='+size;
      //this.iFrameURL = size > 200 ? 'https://recognize-enterprise.youcanbook.me?'+this.urlParams : 'https://recognize-sales.youcanbook.me?'+this.urlParams;
      this.iFrameURL = 'https://recognize-enterprise.youcanbook.me?'+this.urlParams;

      Swal.fire({
          html: '<img src="/assets/icons/ajax-loader-medium.gif" id="scheduleDemoiFrameLoader"><iframe class="scheduleDemoiFrame" src="'+this.iFrameURL+'" style="width: '+($window.outerWidth()-100)+'px; height: '+($window.outerHeight()-100)+'px;" onload="document.getElementById(\'scheduleDemoiFrameLoader\').style.display=\'none\';"></iframe>',
          grow: 'fullscreen',
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: false
      }).then(function(result) {
          if (result.dismiss === 'close' && window.localStorage.getItem('bookedDemo') !== 'true') {
              askQuestionAlert(size);
          }
      });
    };

    function smallCompanyAlert(urlParams) {
        Swal.fire({
            icon: 'question',
            iconHtml: '<i data-feather="users"></i>',
            title: 'For Larger Companies',
            html: '<p>Recognize is designed for companies over 20 employees. Please come back when your organization is a bit larger!</p>',
            showCloseButton: true
        });

        try {
          if (window.feather) {window.feather.replace();}
        } catch(e) {}

    }

    SD.prototype.setupButton = function(e) {
        e.preventDefault();
        this.$targetElement = $(e.target);
        this.iFrameURL = this.$targetElement.data('iframe');
        window.localStorage.setItem('bookedDemo', 'false');

        this.urlParams = R.utils.objectToParamString(R.utils.queryParams(null, this.iFrameURL));

        this.checkForExtrasParams();

        if (this.$targetElement.hasClass("watch-demo")) {
          this.watchVideoAlert( this.$targetElement.data('youtube-id') );
        } else {
          this.openUserCountAlert();
        }

        return false;
    };

    SD.prototype.checkForExtrasParams = function() {
        // Check for affiliates
        var affiliateCookie = readCookie('tap_vid');

        if (affiliateCookie) {
            this.urlParams += '&REFERRAL_VID=' + affiliateCookie;
        }
    };

    SD.prototype.userCountHTML = "<div class='demo-user-count'>" +
                                      "<select name='numberUsers' id='numberUsers' class='user-count-input p-1'>" +
                                        "<option value='199'>100-199 employees</option>" +
                                        "<option value='999'>200-999 employees</option>" +
                                        "<option value='4999'>1,000-4,999 employees</option>" +
                                        "<option value='9999'>5,000-9,999 employees</option>" +
                                        "<option value='10000'>10,000+ employees</option>" +
                                      "</select>" +
                                  "</div>";

    SD.prototype.openUserCountAlert = function() {
        var firstAlert = Swal.fire({
            title: 'Meet with Us',
            text: 'Does your group have over 100 employees?',
            showCloseButton: true,
            icon: 'question',
            iconHtml: '<i data-feather="calendar"></i>',
            showCancelButton: false,
            focusConfirm: false,
            footer: 'Not ready?&nbsp; <a href="/videos">Watch pre-made demo videos now</a>',
            confirmButtonText:
                "Yes, Let's Schedule",
            confirmButtonAriaLabel: 'Schedule a demo',
            cancelButtonAriaLabel: 'Cancel',
            preConfirm: function() {
                this.largeCompanyAlert(101);
            }.bind(this)
        });

        feather.replace();
    };

    SD.prototype.addEvents = function() {
        $document.on('keyup', '#numberUsers', function(e) {
            if (e.keyCode === 13) {
                this.companySizeFactory({
                    value: $(e.target).prop("value")
                });
            }
        }.bind(this));

        $document.on(R.touchEvent, LINK_CLASS, this.setupButton.bind(this));
    };

    return SD;
})(jQuery, window);
